<template>
  <div>
    <Nav :data="nav_data"></Nav>
    <div class="content" ref="scroll">
      <!-- 1---链接 2---文件   3---code码 -->
      <img
        src="../../assets/nav.gif"
        class="xixi"
        alt
        v-drag
        id="drag"
        @click="preventClick"
      />
      <div class="box gWidth">
        <h3 id="home">彬彬教育产品导航</h3>
        <template v-for="(item, index) in list">
          <div :id="'module' + (index + 1)" v-if="item._child" :key="index">
            <template v-if="item.type == 1 || item.type == 3">
              <h4 class="item-box">
                {{ item.title }}
                <div
                  class="product-tab"
                  v-if="item._child && item._child[0]._child"
                >
                  <h5
                    v-for="(item2, index2) in item._child"
                    :key="index2"
                    :class="item.index == index2 ? 'active' : ''"
                    @click="item.index = index2"
                  >
                    {{ item2.title }}
                  </h5>
                </div>
              </h4>
              <div class="pro-box clearfix">
                <template v-if="item._child && item._child[0]._child">
                  <div
                    class="pro-box product-box clearfix"
                    v-for="(items4, indexs4) in item._child"
                    :key="indexs4"
                    v-show="item.index == indexs4"
                  >
                    <div
                      v-for="(child4, cIndex4) in items4._child"
                      :key="cIndex4"
                      v-show="items4._child"
                      :class="
                        (child4.type == 3 || child4.type == 2)
                          ? 'box show box-hover fl'
                          : 'box show fl code'
                      "
                    >
                      <a target="_blank" :href="child4.type == 2 ? 'https://pageweb.beliwin.com/uploads/'+child4.file:(child4.type == 3 || child4.type == 1) ? child4.path : 'javascript:;'" :download="child4.type == 2 ? 'https://pageweb.beliwin.com/uploads/'+child4.file: false">
                        <h5>
                          {{ child4.title }}
                          <i v-if="(child4.type == 3 || child4.type == 2)"></i>
                        </h5>
                        <div>
                          <p>
                            {{ child4.type == 2 ? 'https://pageweb.beliwin.com/uploads/'+child4.file  : child4.type == 3  ? child4.path : child4.title }}
                          </p>
                          <i
                            @click.stop="copyLink($event, child4.path)"
                            v-clipboard:copy="message"
                            v-clipboard:success="onCopy"
                            v-clipboard:error="onError"
                            v-if="(child4.type == 3 || child4.type == 2)"
                          ></i>
                          <em v-if="child4.type == 1"></em>
                        </div>
                        <div class="hidden" v-if="child4.type == 1">
                          <img
                            :src="
                              'https://pageweb.beliwin.com/uploads/' +
                                child4.img
                            "
                            alt
                          />
                        </div>
                      </a>
                    </div>
                    <div v-show="!items4._child">暂无数据</div>
                  </div>
                </template>
                <template v-else>
                  <div
                    class="box show fl"
                    :class="[
                      item1.type == 1 ? 'code' : '',
                      (item1.type == 3 || item1.type == 2)  ? 'box-hover' : '',
                    ]"
                    v-for="(item1, index1) in item._child"
                    :key="index1"
                  >
                    <a target="_blank" :href="item1.type == 2 ? 'https://pageweb.beliwin.com/uploads/'+item1.file:(item1.type == 3 || item1.type == 1) ? item1.path : 'javascript:;'" :download="item1.type == 2 ? 'https://pageweb.beliwin.com/uploads/'+item1.file: false">
                      <h5>
                        {{ item1.title }}
                        <i v-if="item1.type == 3 || item1.type == 2"></i>
                      </h5>
                      <div>
                        <p>
                           {{ item1.type == 2 ? 'https://pageweb.beliwin.com/uploads/'+item1.file  : item1.type == 3  ? item1.path : item1.title }}
                        </p>
                        <i
                          @click.stop="copyLink($event, item1.path)"
                          v-clipboard:copy="message"
                          v-clipboard:success="onCopy"
                          v-clipboard:error="onError"
                          v-if="item1.type == 3 || item1.type == 2"
                        ></i>
                        <em v-if="item1.type == 1"></em>
                      </div>
                      <div class="hidden" v-if="item1.type == 1">
                        <img
                          :src="
                            'https://pageweb.beliwin.com/uploads/' + item1.img
                          "
                          alt
                        />
                      </div>
                    </a>
                  </div>
                </template>
              </div>
            </template>
            <template v-if="item.type == 4">
              <h4 class="item-box">
                {{ item.title }}
                <div
                  class="video-tab"
                  v-if="item._child && item._child[0]._child"
                >
                  <h5
                    v-for="(item2, index2) in item._child"
                    :key="index2"
                    :class="item.index == index2 ? 'active' : ''"
                    @click="item.index = index2"
                  >
                    {{ item2.title }}
                  </h5>
                </div>
              </h4>
              <div>
                <div
                  class="video-box clearfix"
                  v-for="(items7, indexs7) in item._child"
                  :key="indexs7"
                  v-show="item.index == indexs7"
                >
                  <div
                    class="video-item fl show"
                    v-for="(childs7, cIndexs7) in items7._child"
                    :key="cIndexs7"
                    v-show="items7._child"
                    :data-url="childs7.path"
                    @click="playVedio($event)"
                  >
                    <img
                      :src="
                        'https://pageweb.beliwin.com/uploads/' + childs7.img
                      "
                      alt
                      ref="images"
                    />
                    <img src="../../assets/bf.png" class="bf" alt />
                    <div class="video-title" :data-url="childs7.path">
                      <h4 :title="childs7.title">{{ childs7.title }}</h4>
                    </div>
                    <div class="copy">
                      {{ childs7.path }}
                      <i
                        @click.stop="copyLink($event, childs7.path)"
                        v-clipboard:copy="message"
                        v-clipboard:success="onCopy"
                        v-clipboard:error="onError"
                      ></i>
                    </div>
                  </div>
                  <div v-show="!items7._child">暂无数据</div>
                </div>
              </div>
            </template>
            <template v-if="item.type == 2">
              <h4 class="item-box">
                {{ item.title }}
                <div class="shouce-tab">
                  <h5
                    v-for="(item8, index8) in item._child"
                    :key="index8"
                    @click="product(index8)"
                    :class="pros == index8 ? 'active' : ''"
                  >
                    {{ item8.title }}
                  </h5>
                </div>
              </h4>
              <template v-if="item._child[pros]._child && item._child[pros]._child[0]._child">
                <div class="erji-tab">
                  <div
                    v-for="(items8, indexs8) in item._child"
                    :key="indexs8"
                    v-show="pros == indexs8"
                  >
                    <h5
                      v-for="(childs8, cIndexs8) in items8._child"
                      :key="cIndexs8"
                      :class="changeIndexs == cIndexs8 ? 'active' : ''"
                      @click="change2(cIndexs8)"
                    >
                      {{ childs8.title }}
                    </h5>
                  </div>
                </div>

                <table
                  v-for="(items8, indexs8) in item._child"
                  :key="indexs8"
                  class="show"
                  v-show="pros == indexs8"
                >
                  <thead>
                    <tr>
                      <th>文件名称</th>
                      <th>创建时间</th>
                      <th>预览地址</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(childs8, cIndexd8) in items8._child"
                    :key="cIndexd8"
                    v-show="changeIndexs == cIndexd8"
                  >
                    <tr
                      v-for="(cshilds8, csIndexd8) in childs8._child"
                      :key="csIndexd8"
                    >
                      <td>{{ cshilds8.title }}</td>
                      <td>{{ cshilds8.create_time }}</td>
                      <td>
                        <a
                          target="_blank"
                          @click="
                            preview(
                              $event,
                              'https://pageweb.beliwin.com/uploads/' +
                                cshilds8.file
                            )
                          "
                          >点击预览文件</a
                        >
                      </td>
                      <td>
                        <a
                          target="_blank"
                          :href="
                            'https://pageweb.beliwin.com/uploads/' +
                              cshilds8.file
                          "
                          :title="cshilds8.title"
                          v-if="cshilds8.fileType == 'pdf'"
                        >
                          <span style="font-size: 14px;color: #5d6171;"
                            >预览下载</span
                          >
                        </a>
                        <a
                          target="_blank"
                          :href="
                            'https://pageweb.beliwin.com/uploads/' +
                              cshilds8.file
                          "
                          :title="cshilds8.title"
                          v-else
                        >
                          <img src="../../assets/download.png" alt />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-else>
                <table>
                  <thead>
                    <tr>
                      <th>文件名称</th>
                      <th>创建时间</th>
                      <th>预览地址</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody
                    v-for="(childs8, cIndexd8) in item._child"
                    :key="cIndexd8"
                    v-show="pros == cIndexd8"
                  >
                    <tr
                      v-for="(cshilds8, csIndexd8) in childs8._child"
                      :key="csIndexd8"
                    >
                      <td>{{ cshilds8.title }}</td>
                      <td>{{ cshilds8.create_time }}</td>
                      <td>
                        <a
                          target="_blank"
                          @click="
                            preview(
                              $event,
                              'https://pageweb.beliwin.com/uploads/' +
                                cshilds8.file
                            )
                          "
                          >点击预览文件</a
                        >
                      </td>
                      <td>
                        <a
                          target="_blank"
                          :href="
                            'https://pageweb.beliwin.com/uploads/' +
                              cshilds8.file
                          "
                          :title="cshilds8.title"
                          v-if="cshilds8.fileType == 'pdf'"
                        >
                          <span style="font-size: 14px;color: #5d6171;"
                            >预览下载</span
                          >
                        </a>
                        <a
                          target="_blank"
                          :href="
                            'https://pageweb.beliwin.com/uploads/' +
                              cshilds8.file
                          "
                          :title="cshilds8.title"
                          v-else
                        >
                          <img src="../../assets/download.png" alt />
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>
          </div>
        </template>
      </div>
    </div>
    <div class="video-show" v-if="play">
      <div>
        <div class="img">
          <img
            src="https://www.ixlzj.com/static/common/images/video_gb.png"
            alt
            @click="deleteBtns"
          />
        </div>
        <video
          :src="src"
          controls
          autoplay
          controlslist="nodownload"
          draggable="true"
          ref="videos"
        >
          your browser does not support the video +tag
        </video>
      </div>
    </div>
  </div>
</template>
<script>
import { saveAs } from "file-saver";
import Nav from "../../components/Nav";
export default {
  name: "Index",
  data() {
    return {
      message: "",
      play: false,
      src: "",
      list: [],
      files: [],
      url: "",
      pros: 0, // 文件栏使用的属性
      changeIndexs: 0, // 文件栏使用的属性
      format: [],
      nav_data: [],
    };
  },
  components: {
    Nav,
  },
  directives: {
    drag: {
      bind: function(el, binding) {
        let oDiv = el;
        let firstTime = 0,
          lastTime = 0;
        document.onselectstart = function() {
          return false;
        };
        oDiv.onmousedown = (e) => {
          document.getElementById("drag").setAttribute("data-flag", false);
          firstTime = new Date().getTime();
          let disX = e.clientX - oDiv.offsetLeft;
          let disY = e.clientY - oDiv.offsetTop;
          document.onmousemove = (e) => {
            let left = e.clientX - disX;
            let top = e.clientY - disY;
            if (top > 0 && top < document.body.clientHeight - 48) {
              oDiv.style.top = top + "px";
            }
            if (left > 0 && left < document.body.clientWidth - 200) {
              oDiv.style.left = left + "px";
            }
          };
          document.onmouseup = (e) => {
            document.onmousemove = null;
            document.onmouseup = null;
            // onmouseup 时的时间，并计算差值
            lastTime = new Date().getTime();
            if (lastTime - firstTime < 200) {
              document.getElementById("drag").setAttribute("data-flag", true);
            }
          };
          return false;
        };
      },
    },
  },
  mounted() {
    let that = this;
    that.$axios.get("https://pageweb.beliwin.com/api/jh/list").then((res) => {
      let reses = res.data.data;

      // 导航提取
      reses.forEach((item, index) => {
        item.index = 0;
        if (item._child && item._child[0]._child) {
          item._child.forEach((item1, index1) => {
            item1.index = 0;
          });
        }
        let data_obj = {};
        if (item._child) {
          data_obj.id = index;
          data_obj.name = item.title;
          data_obj.url1 = item.icon;
          data_obj.url2 = item.icon_true;
          data_obj.lei = "module" + (index + 1);
          that.nav_data.push(data_obj);
        }
      });

      let files = reses[8];
      if (files._child) {
        for (let i = 0; i < files._child.length; i++) {
          if (files._child[i]._child) {
            for (let j = 0; j < files._child[i]._child.length; j++) {
              if (files._child[i]._child[j]._child) {
                for (
                  let k = 0;
                  k < files._child[i]._child[j]._child.length;
                  k++
                ) {
                  let first = files._child[i]._child[j]._child[
                    k
                  ].file.lastIndexOf(".");
                  let fileNameLength =
                    files._child[i]._child[j]._child[k].file.length; //取到文件名长度
                  let fileFormat = files._child[i]._child[j]._child[
                    k
                  ].file.substring(first + 1, fileNameLength); //截
                  if (
                    fileFormat == "pdf" ||
                    fileFormat == "png" ||
                    fileFormat == "jpg"
                  ) {
                    files._child[i]._child[j]._child[k].fileType = "pdf";
                  } else {
                    files._child[i]._child[j]._child[k].fileType = "else";
                  }
                  reses[8] = files;
                }
              }else{
                if(files._child[i]._child[j].file){
                  let first = files._child[i]._child[j].file.lastIndexOf(".");
                  let fileNameLength =
                    files._child[i]._child[j].file.length; //取到文件名长度
                  let fileFormat = files._child[i]._child[j].file.substring(first + 1, fileNameLength); //截
                  if (
                    fileFormat == "pdf" ||
                    fileFormat == "png" ||
                    fileFormat == "jpg"
                  ) {
                    files._child[i]._child[j].fileType = "pdf";
                  } else {
                    files._child[i]._child[j].fileType = "else";
                  }
                  reses[8] = files;
                }
              }
            }
          }
        }
      }
      that.list = reses;
      console.log(reses);
    });
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    getBlob(url) {
      return new Promise((resolve) => {
        const xhr = new XMLHttpRequest();
        xhr.open("GET", url, true, { type: "application/pdf;charset-UTF-8" });
        xhr.responseType = "arraybuffer";
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
            this.loading = false;
          }
        };
        xhr.onerror = function() {
          console.error("could not download file");
        };
        xhr.send();
      });
    },
    preventClick() {
      // 点击事件触发时，判断当前状态是拖拽还是点击，若是拖拽，直接返回不继续执行
      const isDrag = document.getElementById("drag").getAttribute("drag-flag");
      if (isDrag === "true") {
        return false;
      }
    },
    handleScroll() {
      //获取滚动时的高度
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      let oneHeight = this.$refs.scroll.offsetHeight;
      let _set = null;
      clearTimeout(_set);
      _set = setTimeout(function() {
        document.getElementById("drag").style.top = scrollTop + 200 + "px";
        clearTimeout(_set);
      }, 900);
    },
    // 复制成功时的回调函数
    onCopy(e) {
      this.$message({
        type: "success",
        message: "复制成功！",
        duration: "2000",
      });
    },
    // 复制失败时的回调函数
    onError(e) {
      this.$message({
        type: "error",
        message: "",
        duration: "2000",
      });
    },
    copyLink(e, path) {
      e.preventDefault();
      let that = this;
      that.message = path;
    },
    product(index) {
      // 文件栏使用的方法
      let that = this;
      that.pros = index;
    },
    change2(index) {
      // 文件栏使用的方法
      let that = this;
      that.changeIndexs = index;
      that.changeCondition = true;
    },
    links(e, path) {
      window.open(path, "_self");
    },

    // 点击播放视频
    playVedio(e) {
      let that = this;
      let src = e.currentTarget.getAttribute("data-url");
      that.src = src;
      that.play = true;
    },
    deleteBtns() {
      let that = this;
      that.play = false;
    },
    preview(e, url) {
      e.preventDefault;
      console.log(e.target, url);
      let new_url = url;
      let first = url.lastIndexOf(".");
      let nameLength = url.length; //取到文件名长度
      let format = url.substring(first + 1, nameLength); //截
      if (format == "pdf" || format == "png" || format == "jpg") {
        e.target.href = new_url;
      } else {
        e.target.href =
          "https://view.officeapps.live.com/op/view.aspx?src=" + new_url;
      }
    },
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleScroll);
  },
};
</script>
<style lang="scss" scoped>
.xixi {
  width: auto;
  height: 200px;
  position: absolute;
  right: 0px;
  top: 20px;
  z-index: 100;
}
.gWidth {
  position: relative;
}

/* content */
.content {
  width: calc(100% - 238px);
  margin-left: 238px;
  padding-bottom: 90px;
  // overflow: hidden;
  .box {
    h3 {
      font-size: 28px;
      font-weight: bold;
      color: #414857;
      line-height: 28px;
      padding: 60px 0 31px;
    }
    h4 {
      font-size: 20px;
      color: #ee8031;
      margin-bottom: 20px;
    }
    .pro-box {
      .box {
        width: 175px;
        margin: 0 24px 20px 0;
        height: 96px;
        cursor: pointer;
        background: #fff;
        position: relative;
        box-shadow: 0 2px 30px 0 #f1f7fb;
        border-radius: 10px;
        transition: all 0.6s;
        & > a {
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        }
        &:nth-child(6n) {
          margin-right: 0;
        }
        h5 {
          width: 86%;
          font-size: 16px;
          font-weight: normal;
          margin: 0 auto;
          padding-top: 20px;
          transition: all 0.6s;
          position: relative;
          z-index: 2;
          color: #000;
          overflow: hidden;
          padding-right: 14px;
          box-sizing: border-box;
          white-space: nowrap;
          text-overflow: ellipsis;
          i {
            width: 14px;
            height: 14px;
            background: url("../../assets/r.png") no-repeat center;
            background-size: contain;
            display: block;
            position: absolute;
            right: 0;
            bottom: 2px;
          }
        }
        em {
          width: 12px;
          height: 12px;
          display: block;
          background-image: url("../../assets/code.png");
          background-size: cover;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        div {
          width: 86%;
          margin: 0 auto;
          position: relative;
          margin-top: 16px;
          i {
            width: 12px;
            height: 14px;
            display: block;
            background: url("../../assets/href.png") no-repeat center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
          }
        }
        p {
          color: #bbb;
          width: 120px;
          font-size: 12px;
          line-height: 12px;
          transition: all 0.6s;
          position: relative;
          z-index: 2;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        input {
          opacity: 0;
          position: absolute;
          left: 0;
          bottom: 0;
          z-index: -1;
        }
        .hidden {
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 95px;
          width: 130px;
          height: 130px;
          background-color: #fff;
          box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.2);
          display: none;
          &:before {
            content: "";
            position: absolute;
            top: -20px;
            left: 50%;
            transform: translateX(-50%);
            width: 0;
            height: 0;
            border: 10px solid #fff;
            border-color: transparent transparent #fff transparent;
          }
          img {
            width: 110px;
            height: 110px;
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          }
        }
        &:hover {
          z-index: 11 !important;
        }
      }
      .box-hover {
        &:hover {
          background-color: #ee8031;
          h5 {
            color: #fff;
          }
          i {
            background-image: url("../../assets/r1.png");
          }
          p {
            color: #fff;
          }
          div {
            i {
              background-image: url("../../assets/href1.png");
            }
          }
        }
      }
      .code {
        &:hover {
          .hidden {
            display: block;
          }
        }
      }
    }
  }
}

/* 视频文件 */
.video-box {
  //   display: none;
  padding-bottom: 30px;
  &.active {
    display: block;
  }
  .video-item {
    width: 210px;
    padding-bottom: 30px;
    background-color: #fff;
    box-shadow: 0 2px 30px 0 #f1f7fb;
    position: relative;
    cursor: pointer;
    margin-right: 20px;
    margin-bottom: 20px;
    img {
      width: 188px;
      height: 104px;
      object-fit: cover;
      display: block;
      margin: 10px auto 0;
      border-radius: 10px;
      transition: all 0.6s;
      &.bf {
        width: 30px;
        height: 30px;
        display: block;
        position: absolute;
        left: 50%;
        top: 30%;
        transform: translate(-50%, -50%);
      }
      &:not(.bf):hover {
        transform: scale(1.05);
      }
      &.down {
        width: 16px;
        height: auto;
        margin: 0;
        border-radius: 0;
        position: absolute;
        right: 10px;
        bottom: 8px;
      }
    }
    .video-title {
      width: 188px;
      margin: 0 auto;
      font-size: 18px;
      line-height: 24px;
      h4 {
        margin-top: 10px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        color: #333;
      }
    }
    .copy {
      width: 86%;
      margin: 0 auto;
      position: relative;
      margin-top: 16px;
      overflow: hidden;
      padding-right: 14px;
      box-sizing: border-box;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 14px;
      color: #909399;
      input {
        opacity: 0;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      i {
        width: 12px;
        height: 14px;
        display: block;
        background: url("../../assets/href.png") no-repeat center;
        background-size: cover;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

// 点击播放视频按钮
.video-show {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 9999;
  background-color: rgba(0, 0, 0, 0.8);
  & > div {
    width: 1100px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    background-color: #000;
    .img {
      width: 20px;
      height: 20px;
      cursor: pointer;
      position: absolute;
      right: -5px;
      top: -5px;
      z-index: 12;
      border-radius: 50%;
      background-color: #ddd;
      border: 2px solid #fff;
      img {
        width: 10px;
        height: 10px;
        display: block;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
  video {
    width: 1100px;
    height: 540px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    background-color: #000;
  }
}

// .show {
//   transition: all 0.6s;
//   transform: translateY(20px);
//   opacity: 0;
// }

// .show.on {
//   transform: translateY(0);
//   opacity: 1;
// }

.erji-tab {
  margin-top: -40px;
}

.video-tab,
.shouce-tab,
.erji-tab,
.product-tab,
.product1-tab,
.product2-tab {
  font-size: 0;
  padding-left: 20px;
  // margin-bottom: 20px;
  display: inline-block;
}

.video-tab h5,
.shouce-tab h5,
.erji-tab h5,
.product-tab h5,
.product1-tab h5,
.product2-tab h5 {
  font-size: 16px;
  font-weight: normal;
  color: #5d6171;
  display: inline-block;
  cursor: pointer;
  margin-right: 40px;
}

.video-tab h5.active,
.shouce-tab h5.active,
.erji-tab h5.active,
.product-tab h5.active,
.product1-tab h5.active,
.product2-tab h5.active {
  font-weight: bold;
  font-size: 18px;
}

// table
table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 2px 30px 0 #f0f5f8;
  margin-bottom: 100px;
  tbody {
    display: block;
    max-height: 245px;
    overflow-y: auto;
    tr {
      border-bottom: 1px solid #f5f7fa;
      cursor: pointer;
      transition: all 0.6s;
      display: table;
      width: 100%;
      table-layout: fixed;
      th,
      td {
        width: 25%;
        height: 48px;
        line-height: 48px;
        font-size: 14px;
        color: #909399;
        text-align: center;
        a {
          color: #5d6171;
          text-align: center;
        }
      }
      &:hover {
        background-color: #f5f7fa;
      }
    }
  }
  thead {
    display: table;
    width: 100%;
    table-layout: fixed;
    tr {
      width: 25%;
      height: 48px;
      line-height: 48px;
      font-size: 14px;
      color: #333;
      text-align: center;
      a {
        color: #5d6171;
        text-align: center;
      }
    }
  }
}

/*滚动条整体样式*/
table tbody::-webkit-scrollbar {
  width: 6px;
}

/*滚动条里面小方块*/
table tbody::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #e5b476;
}

table tbody::-webkit-scrollbar-thumb {
  background-color: #ee8031;
}

/*滚动条里面轨道*/
table tbody::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #ededed;
}

/* 未来学院 */
.product-box {
  padding-bottom: 30px;
}

@media screen and (max-width: 1500px) {
  .gWidth {
    width: 960px !important;
    margin-left: auto;
    margin-right: auto;
  }
  .content > .box .pro-box > .box {
    width: 175px;
    margin: 0 20px 20px 0;
    height: 96px;
    cursor: pointer;
    background: #fff;
    position: relative;
    box-shadow: 0 2px 30px 0 #f1f7fb;
    border-radius: 10px;
    transition: all 0.6s;
  }
  .content > .box .pro-box > .box:nth-child(5n) {
    margin-right: 0;
  }
  .content > .box .pro-box > .box:nth-child(6n) {
    margin-right: 20px;
  }
}
</style>
