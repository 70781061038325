<template>
  <div>
    <div class="nav-box">
      <img src="../assets/logo.png" class="logo" alt="" />

      <ul>
        <li
          v-for="(item, index) in data"
          :class="indextab !== item.id || 'active'"
          :key="index"
          @click="tablist(item.id, item.lei)"
        >
          <img :src="'https://pageweb.beliwin.com/uploads/' + item.url1" alt="" />
          <img :src="'https://pageweb.beliwin.com/uploads/' + item.url2" class="a" alt="" />
          <p>{{ item.name }}</p>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      indextab: 0
    };
  },
  props: ['data'],
  methods: {
    tablist(id, lei) {
      let that = this;
      that.indextab = id;
      document
        .getElementById(lei)
        .scrollIntoView({ block: "start", behavior: "smooth" });
    }
  }
};
</script>
<style lang="scss" scoped>
/* 导航 */
.nav-box {
  width: 238px;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 30px 0px rgba(194, 199, 214, 0.38);
  position: fixed;
  .logo {
    width: 182px;
    height: auto;
    display: block;
    margin: 40px auto;
  }
}

.content .box .xixi {
  width: auto;
  height: 200px;
  position: fixed;
  right: 0px;
  bottom: 0;
  z-index: 1;
}

.nav-box ul {
  position: relative;
  z-index: 2;
  li {
    width: 100%;
    height: 61px;
    line-height: 34px;
    display: block;
    cursor: pointer;
    transition: all 0.6s;
    img {
      width: 18px;
      height: auto;
      display: inline-block;
      vertical-align: middle;
      margin-left: 50px;
      margin-right: 20px;
      &.a {
        display: none;
      }
    }
    p {
      font-size: 16px;
      font-weight: bold;
      color: #5d6171;
      display: inline-block;
      vertical-align: middle;
    }
    &.active {
      background-color: #ee8031;
      p {
        color: #ffffff;
      }
      img {
        display: none;
        &.a {
          display: inline-block;
        }
      }
    }
  }
}
</style>
